<template>
  <div class="succeed-big dfc flex-center">
    <div class="succeed-box">
      <img src="../assets/images/succeed.png" alt="" class="w100 h100" />
    </div>
    <div class="fctheme fw500 fs36">您已成功报名参与此会议</div>
    <div v-if="info" class="time-box fs28 fw400 fc333">
      会议时间 {{ info.hold_datetime }}
    </div>
    <div class="button-box">
      <icy-button @click.native="goMe" width="3.16rem" height="0.76rem"
        >进入“我的会议”</icy-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      meetingId: "",
      userId: "",
      info: null
    };
  },
  created() {
    this.meetingId = parseInt(this.$route.query.id);
    this.initInfo();
  },
  methods: {
    async initInfo() {
      const resp = await this.$API
        .get("meetingDetail", { id: this.meetingId })
        .then(resp => resp);
      if (resp.code == 1000) {
        this.info = resp.data;
        // this.joinNum = resp.data.meeting_user_count;
      }
    },
    goMe() {
      this.$go("meetingDetail", { id: this.meetingId, type: 2, invite: 1 });
    }
  }
};
</script>

<style lang="less" scoped>
.succeed-big {
  width: 7.5rem;
  .succeed-box {
    width: 1.6rem;
    height: 1.6rem;
    margin-top: 2.68rem;
  }
  .time-box {
    margin-top: 0.12rem;
  }
  .button-box {
    margin-top: 0.64rem;
  }
}
</style>
